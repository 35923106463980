import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  month: null,
  days: [],
  active_period: '',
}
const shahmatka = createSlice({
  name: 'shahmatka',
  initialState: initialState,
  reducers: {
    set_month(state, action) {
      state.month = action.payload
    },
    set_days(state, action) {
      state.days = action.payload
    },
    set_active_period(state, action) {
      state.active_period = action.payload
    }
  },
});

export const {
  set_month,
  set_days,
  set_active_period,
} = shahmatka.actions;

export default shahmatka.reducer;
