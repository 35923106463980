import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  title: "",
  description: "",

  // локальная загрузка изображений (DropZone)
  image_local: [],
  // ссылка на cloudinary
  image: "",
  price: null,
  gallery: [],
  gallery_local: [],
}
const service = createSlice({
  name: 'service',
  initialState: initialState,
  reducers: {
    /** Сброс state */
    reset_service(state) {
      Object.assign(state, initialState);
    },
    set_service(state, action) {
      state = action.payload
      return state
    },
    set_title(state, action) {
      state.title = action.payload
    },
    set_description(state, action) {
      state.description = action.payload
    },
    set_price(state, action) {
      state.price = action.payload
    },
    set_image_local(state, action) {
      state.image_local = action.payload
    },
    set_image(state, action) {
      state.image = action.payload
    },
    set_gallery_local(state, action) {
      state.gallery_local = action.payload
    },
    set_gallery(state, action) {
      state.gallery = action.payload
    },
    delete_gallery_image(state, action) {
      state.gallery = state.gallery?.filter(item => item?.uuid !== action?.payload)
      return state
    },
  },
});

export const {
  reset_service,
  set_service,
  set_title,
  set_price,
  set_description,
  set_image,
  set_image_local,
  set_gallery_local,
  set_gallery,
  delete_gallery_image
} = service.actions;

export default service.reducer;
