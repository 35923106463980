import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createClient} from "@supabase/supabase-js";
import {getSupabase} from "../../utils/supabase";
import {Notifications_table} from "../../configs/supabase";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {set_loading_notification} from "../../store/errors-server";
import {set_event, set_notifications} from "../../store/Notifications";
import {useLocation} from "react-router-dom";
import {Notification, toast} from "components/ui";

export const SupabaseNotice = () => {
  const sessionContext = useSessionContext();
  const access_supabase = useSelector((state) => state.auth.session.access_supabase);
  const event = useSelector((state) => state.notifications.event);

  const location = useLocation();

  const dispatch = useDispatch();

  const supabaseRealtime = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY, {
    realtime: {
      headers: {
        apikey: process.env.REACT_APP_SUPABASE_KEY,
      },
      params: {
        accessToken: access_supabase,
      },
    },
  });

  /** Подписка на таблицу с realtime */
  useEffect(() => {
    if (access_supabase) {
      supabaseRealtime
        .channel("Notification")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: Notifications_table,
            filter: `user=eq.${sessionContext.userId}`,
          },
          (payload) => {
            dispatch(set_event(payload?.new));
            //sendMessage("change_notification")
            if (location.pathname !== "/notifications") {
              getSupabase(access_supabase)
                .from(Notifications_table)
                .select("*", {count: "exact"})
                .not("message", "is", null)
                .neq("message", "")
                .eq("user", sessionContext.userId)
                .range(0, 1)
                .then((res) => {
                  if (!res.error) {
                    dispatch(set_notifications(res));
                  }
                });
            }
          },
        )
        .subscribe();
    }
    return () => {
      supabaseRealtime.removeAllChannels();
    };
  }, [access_supabase, dispatch, location.pathname, sessionContext.userId, supabaseRealtime]);

  /** Запрос списка уведомлений при старте, чтобы отобразить количество в колокольчике */
  useEffect(() => {
    if (access_supabase && sessionContext.userId && location.pathname !== "/notifications") {
      dispatch(set_loading_notification(true));
      getSupabase(access_supabase)
        .from(Notifications_table)
        .select("*", {count: "exact"})
        .not("message", "is", null)
        .neq("message", "")
        .eq("user", sessionContext.userId)
        .range(0, 1)
        .then((res) => {
          dispatch(set_loading_notification(false));
          if (!res.error) {
            dispatch(set_notifications(res));
          }
        });
    }
  }, [access_supabase, sessionContext.userId, dispatch, location.pathname]);

  const openNotification = (message = "", placement = "top-center") => {
    toast.push(<Notification type="success" title={message} />, {
      placement: placement,
    });
  };
  useEffect(() => {
    if (event?.uuid && event?.message) {
      openNotification(event?.message);
    }
  }, [event]);

  return <></>;
};
