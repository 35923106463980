import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import errors_server from './errors-server';
import {notifications} from "./Notifications";
import mailing from "./mailing";
import service from "./service";
import cottage from "./cottage";
import bath from "./bath";
import gazebo from "./gazebo";
import dome from "./dome";
import shahmatka from "./shahmatka";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        errors_server,
        notifications,
        mailing,
        service,
        cottage,
        bath,
        gazebo,
        dome,
        shahmatka,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
