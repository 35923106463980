import axios from "axios";
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {set_error_message, set_error_status, set_loading} from "../../store/errors-server";
import {setUser} from "../../store/auth/userSlice";

export const useGetMe = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const tokens = useSelector((state) => state.auth.session);
  const error_message = useSelector((state) => state.errors_server.error_message);

  /** Запрос личных данных */
  const get_me = useCallback(() => {
    dispatch(set_error_message(null));
    dispatch(set_error_status(null));
    dispatch(set_loading(true));
    return axios
      .get(`${process.env.REACT_APP_WEBSITE_AUTH_API}/users/me/`, {
        headers: {
          authorization: tokens.access ? `Bearer ${tokens.access}` : "",
        },
      })
      .then((res) => {
        dispatch(set_loading(false));
        dispatch(setUser(res.data));
        if (
          (res?.data?.email || res?.data?.phone)
          &&
          (res?.data?.roles?.length === 0 || res?.data?.roles?.some(role => role === "user"))
        ) {
          document.location.href = process.env.REACT_APP_WEBSITE
        }
      })
      .catch(() => {
        dispatch(set_loading(false));
        dispatch(set_error_message("Что-то пошло не так"));
        setTimeout(() => {
          document.location.href = "/"
        }, 5000)
      });
  }, [dispatch, tokens.access]);

  useEffect(() => {
    if (!user?.user_id && tokens.access && !error_message) {
      get_me();
    }
  }, [user?.user_id, tokens.access, get_me, error_message]);
};
