import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  message: "",
  selected_phones: []
}
const mailing = createSlice({
  name: 'mailing',
  initialState: initialState,
  reducers: {
    set_message(state, action) {
      state.message = action.payload
    },
    set_selected_phones(state, action) {
      state.selected_phones = action.payload
    },
    add_selected_phones(state, action) {
      state.selected_phones.push(action.payload);
    },
    del_selected_phones(state, action) {
      state.selected_phones = state.selected_phones.filter((phone) => phone !== action.payload);
      return state;
    },
  },
});

export const {
  set_selected_phones,
  set_message,
  del_selected_phones,
  add_selected_phones
} = mailing.actions;

export default mailing.reducer;
